import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { AuthProvider } from './context/AuthContext';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Assets from './pages/Assets';
import Strategies from './pages/Strategies';
import Settings from './pages/Settings';
import Identification from './pages/Account/Identification';
import Security from './pages/Account/Security';
import Payment from './pages/Account/Payment';
import ApiManagement from './pages/Account/ApiManagement';
import AccountStatement from './pages/Account/AccountStatement';
import CustomLayout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';
import AddStrategy from './pages/Strategies/component/AddStrategy';

const App: React.FC = () => (
    <ConfigProvider>
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Dashboard />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/assets"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Assets />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/strategies"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Strategies />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Settings />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                      <Route
                        path="/strategies/add"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <AddStrategy />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account/identification"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Identification />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account/security"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Security />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account/payment"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <Payment />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account/api-management"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <ApiManagement />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/account/account-statement"
                        element={
                            <PrivateRoute>
                                <CustomLayout>
                                    <AccountStatement />
                                </CustomLayout>
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Router>
        </AuthProvider>
    </ConfigProvider>
);

export default App;
