export function configureFakeBackend() {
    let users = [{ id: 1, username: 'admin', password: 'admin', firstName: 'Admin', lastName: 'User' }];
    let realFetch = window.fetch;
    window.fetch = function (url, opts) {
        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(() => {
                // authenticate
                if (typeof url === 'string' && url.endsWith('/users/authenticate') && opts?.method === 'POST') {
                    interface Params {
                        username: string;
                        password: string;
                    }
                    let params: Params;

                    if (typeof opts.body === 'string') {
                        params = JSON.parse(opts.body);
                    } else {
                        // Handle the case where opts.body is not a string.
                        // For example, you might want to set params to null or an empty object:
                        params = { username: '', password: '' };
                        // Or throw an error if this situation is unexpected:
                        // throw new Error('Expected opts.body to be a string');
                    }
                    let filteredUsers = users.filter(user => {
                        return user.username === params.username && user.password === params.password;
                    });

                    if (filteredUsers.length) {
                        let user = filteredUsers[0];
                        let responseJson = {
                            id: user.id,
                            username: user.username,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            token: 'fake-jwt-token'
                        }; const response = new Response(JSON.stringify(responseJson), {
                            status: 200, // Assuming a successful response
                            statusText: "OK",
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        resolve(response);
                    } else {
                        reject('Username or password is incorrect');
                    }

                    return;
                }

                // pass through any requests not handled above
                realFetch(url, opts).then(response => resolve(response)).catch(error => reject(error));
            }, 500);
        });
    };
}
