import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import ProCard from "@ant-design/pro-card";

const AccountLimits: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    fiatLimits: "2M USD Daily",
    cryptoDepositLimit: "Unlimited",
    cryptoWithdrawalLimit: "8M USDT Daily",
    p2pTransactionLimits: "Unlimited",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFormChange = (changedValues: any, allValues: any) => {
    setFormData(allValues);
  };

  return (
    <>
      <ProCard
        title="Account Limits"
        extra={
          <Button type="primary" onClick={showModal}>
            Edit
          </Button>
        }
        bordered
        headerBordered
      >
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>Fiat Deposit & Withdrawal Limits:</td>
              <td>{formData.fiatLimits}</td>
            </tr>
            <tr>
              <td>Crypto Deposit Limit:</td>
              <td>{formData.cryptoDepositLimit}</td>
            </tr>
            <tr>
              <td>Crypto Withdrawal Limit:</td>
              <td>{formData.cryptoWithdrawalLimit}</td>
            </tr>
            <tr>
              <td>P2P Transaction Limits:</td>
              <td>{formData.p2pTransactionLimits}</td>
            </tr>
          </tbody>
        </table>
      </ProCard>
      <Modal
        title="Edit Account Limits"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="OK"
        cancelText="Cancel"
      >
        <Form
          layout="vertical"
          onValuesChange={onFormChange}
          initialValues={formData}
        >
          <Form.Item label="Fiat Deposit & Withdrawal Limits" name="fiatLimits">
            <Input />
          </Form.Item>
          <Form.Item label="Crypto Deposit Limit" name="cryptoDepositLimit">
            <Input />
          </Form.Item>
          <Form.Item
            label="Crypto Withdrawal Limit"
            name="cryptoWithdrawalLimit"
          >
            <Input />
          </Form.Item>
          <Form.Item label="P2P Transaction Limits" name="p2pTransactionLimits">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AccountLimits;
