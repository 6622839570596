import React from "react";
import { Button, Modal, Form, Input } from "antd";

const P2PPaymentMethods: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const paymentMethods = [
    "7-Eleven",
    "A-Bank",
    "ABA",
    "ABB Bank",
    "ABN AMRO",
    "Abu Dhabi Commercial Bank ADCB",
    "Abyssinia",
    "ACBA Bank",
    "Access Bank",
    "Accordbank",
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: 3 }}>
          <p>
            P2P payment methods: When you sell cryptocurrencies, the payment
            method added will be displayed to buyer as options to accept
            payment, please ensure that the account owner’s name is consistent
            with your verified name on Binance. You can add up to 20 payment
            methods.
          </p>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            Add a Payment Method
          </Button>
        </div>
      </div>
      <Modal
        title="Select Payment Method"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="All Payment Methods" name="paymentMethod">
            <Input.Search placeholder="Search payment methods" />
          </Form.Item>
          <div>
            {paymentMethods.map((method, index) => (
              <p key={index}>{method}</p>
            ))}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default P2PPaymentMethods;
