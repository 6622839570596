import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import ProCard from "@ant-design/pro-card";

const MyProfile: React.FC = () => {
  const [isNicknameModalVisible, setIsNicknameModalVisible] = useState(false);
  const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false);
  const [nickname, setNickname] = useState("");
  const [avatar, setAvatar] = useState("");

  const showNicknameModal = () => {
    setIsNicknameModalVisible(true);
  };

  const showAvatarModal = () => {
    setIsAvatarModalVisible(true);
  };

  const handleNicknameOk = (values: any) => {
    setNickname(values.nickname);
    setIsNicknameModalVisible(false);
  };

  const handleAvatarOk = (values: any) => {
    setAvatar(values.avatar);
    setIsAvatarModalVisible(false);
  };

  const handleCancel = () => {
    setIsNicknameModalVisible(false);
    setIsAvatarModalVisible(false);
  };

  return (
    <ProCard title="My Profile" bordered headerBordered>
      <div>
        <p>Nickname: {nickname}</p>
        <Button onClick={showNicknameModal}>Edit</Button>
        <Modal
          title="Edit Nickname"
          visible={isNicknameModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form onFinish={handleNicknameOk}>
            <Form.Item label="Nickname" name="nickname">
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form>
        </Modal>
      </div>
      <div>
        <p>Avatar: {avatar}</p>
        <Button onClick={showAvatarModal}>Edit</Button>
        <Modal
          title="Edit Avatar"
          visible={isAvatarModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form onFinish={handleAvatarOk}>
            <Form.Item label="Avatar URL" name="avatar">
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form>
        </Modal>
      </div>
    </ProCard>
  );
};

export default MyProfile;
