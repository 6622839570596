import React from 'react';
import StrategyList from './component/StrategyList';
import { Button, Card, Col, Row } from 'antd';
import { useNavigate } from "react-router-dom";

const Strategies: React.FC = () => {
  const navigate = useNavigate();

  const navigateToAddStrategy = () => { 
    navigate("/strategies/add");
  };

  return (
    <>
      <Row gutter={[24, 24]} style={{ marginBottom: 16 }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={navigateToAddStrategy}>Add Strategy</Button>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card>
            <StrategyList />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Strategies;
