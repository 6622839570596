import React from "react";
import { Row, Col, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import "./Hero.css";

const { Title, Paragraph } = Typography;

const Hero: React.FC = () => (
  <>
    <div className="banner hero">
      
      <div className="bg-overlay"></div>

      <div
        className="banner-anim-elem"
        style={{
          display: "block",
          position: "absolute",
          transform: "translate(0px, 0px)",
          width: "100%",
        }}
      >
        <div className="bg-wrapper">
          <svg
            height="100%"
            style={{
              opacity: "1",
            }}
            viewBox="0 0 1440 451"
            width="100%"
          >
            <g
              fill="none"
              fillRule="evenodd"
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              transform="translate(-30, 0)"
            >
              <g id="Group-9" opacity="0.3">
                <g
                  style={{
                    transform: "translate(0px, -9.18483px)",
                  }}
                >
                  <g
                    id="r-1"
                    style={{
                      transform: "translateX(5.29872px)",
                    }}
                  >
                    <rect
                      fill="#FFFFFF"
                      height="60"
                      id="Rectangle-6"
                      rx="2"
                      transform="translate(41.000000, 169.000000) rotate(-27.000000) translate(-41.000000, -169.000000) "
                      width="60"
                      x="11"
                      y="139"
                    />
                  </g>
                </g>
                <g
                  style={{
                    transform: "translate(0px, -16.9803px)",
                  }}
                >
                  <g
                    id="p-1"
                    style={{
                      transform: "translateX(-5.29872px)",
                    }}
                  >
                    <polygon
                      id="Polygon"
                      points="139 77 152 100 126 100"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      transform="translate(139.000000, 17.500000) rotate(45.000000) translate(-139.000000, -17.500000) "
                    />
                  </g>
                </g>
                <g
                  style={{
                    transform: "translate(0px, 2.899px)",
                  }}
                >
                  <g
                    id="p-2"
                    style={{
                      transform: "translateX(7.94808px)",
                    }}
                  >
                    <polygon
                      id="Polygon"
                      points="180 439 188 453 172 453"
                      stroke="#FFFFFF"
                      transform="translate(180.000000, 446.000000) rotate(-67.000000) translate(-180.000000, -446.000000) "
                    />
                  </g>
                </g>
                <g
                  style={{
                    transform: "translate(0px, -0.190293px)",
                  }}
                >
                  <g
                    id="c-1"
                    style={{
                      transform: "translateX(-7.94808px)",
                    }}
                  >
                    <circle
                      cx="256"
                      cy="357"
                      fill="#FFFFFF"
                      id="Oval"
                      r="10"
                      stroke="#FFFFFF"
                      strokeWidth="3"
                    />
                  </g>
                </g>
              </g>
              <g
                id="Group-8"
                stroke="#FFFFFF"
                transform="translate(1219.000000, 69.000000)"
              >
                <g
                  style={{
                    transform: "translate(0px, 8.97608px)",
                  }}
                >
                  <g
                    id="r-2"
                    style={{
                      transform: "translateX(-2.64936px)",
                    }}
                  >
                    <rect
                      height="64"
                      id="Rectangle-6"
                      opacity="0.3"
                      rx="2"
                      strokeWidth="2"
                      transform="translate(47.000000, 343.000000) rotate(-45.000000) translate(-47.000000, -343.000000) "
                      width="64"
                      x="15"
                      y="311"
                    />
                  </g>
                </g>
                <g
                  style={{
                    transform: "translate(0px, 0px)",
                  }}
                >
                  <g
                    id="c-2"
                    style={{
                      transform: "translateX(10.5974px)",
                    }}
                  >
                    <circle
                      cx="155"
                      cy="13"
                      fill="#FFFFFF"
                      id="Oval"
                      opacity="0.4"
                      r="13"
                      strokeWidth="3"
                    />
                  </g>
                </g>
                <g
                  style={{
                    transform: "translate(0px, -9.27321px)",
                  }}
                >
                  <g
                    id="c-3"
                    style={{
                      transform: "translateX(-7.94808px)",
                    }}
                  >
                    <circle
                      cx="211"
                      cy="225"
                      id="Oval"
                      opacity="0.4"
                      r="8"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="text-wrapper">
          <div>
            <div
              className="introduce"
              style={{
                opacity: "1",
                transform: "translate(0px, 0px)",
              }}
            >
              <Title style={{ fontSize: '3.0em' }}>
                <span style={{color:"lightgray"}}>Join the Future Of</span> <br/>
                <span style={{ color: "white" }}>Algorithmic Crypto Trading</span> 
              </Title>
              <Paragraph style={{ color: "white", fontSize: '1.0em' }}>
                Revolutionize trading with our automated platform, a
                foundational layer-0 protocol for seamless cryptocurrency market
                transactions.
              </Paragraph>
            </div>

            <div
              className="button-wrapper"
              style={{
                opacity: "1",
                transform: "translate(0px, 0px)",
              }}
            >
              {/* <Button type="default" size="large">
                <Link to="/dashboard">Launch App</Link>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Hero;
