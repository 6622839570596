import React, { useState } from "react";
import { Button, Modal, Form, Input, Tabs, Table } from "antd";
import ProCard from "@ant-design/pro-card";
import P2PPaymentMethods from "./components/P2PPaymentMethods";
import BuyCrypto from "./components/BuyCrypto";

const { TabPane } = Tabs;

const Payment: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalCreditCardType, setCreditCardModalType] = useState<
    "add" | "edit"
  >("add");
  const [selectedCreditCardRecord, setSelecteCreditCardRecord] =
    useState<any>(null);

  const showCreditCardModal = (type: "add" | "edit", record?: any) => {
    setCreditCardModalType(type);
    setSelecteCreditCardRecord(record);
    setIsModalVisible(true);
  };

  const handleCreditCardOk = () => {
    // Here you would handle the form submission for both add and edit operations
    setIsModalVisible(false);
  };

  const handleCreditCardCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const paymentMethods = [
    "7-Eleven",
    "A-Bank",
    "ABA",
    "ABB Bank",
    "ABN AMRO",
    "Abu Dhabi Commercial Bank ADCB",
    "Abyssinia",
    "ACBA Bank",
    "Access Bank",
    "Accordbank",
  ];

  const cardData = [
    {
      key: "1",
      cardType: "MasterCard",
      cardNumber: "**** **** **** 1234",
    },
    {
      key: "2",
      cardType: "Visa",
      cardNumber: "**** **** **** 5678",
    },
    {
      key: "3",
      cardType: "Visa Electron",
      cardNumber: "**** **** **** 9012",
    },
  ];

  const columns = [
    {
      title: "Card Type",
      dataIndex: "cardType",
      key: "cardType",
    },
    {
      title: "Card Number",
      dataIndex: "cardNumber",
      key: "cardNumber",
    },
    {
      title: "Action",
      key: "action",
      render: () => <Button danger>Remove</Button>,
    },
  ];

  return (
    <div>
      <h1>Payment</h1>
      <ProCard tabs={{ type: "card" }}>
        <TabPane tab="P2P" key="1">
          <P2PPaymentMethods />
        </TabPane>
        <TabPane tab="Buy Crypto" key="2">
          <BuyCrypto />
        </TabPane>
      </ProCard>
    </div>
  );
};

export default Payment;
