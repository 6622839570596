import React from 'react';
import { Modal, Form, Select } from 'antd';

const { Option } = Select;

interface AddIndicatorProps {
  isModalVisible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  form: ReturnType<typeof Form.useForm>[0];
  editingIndex: number | null;
}

const AddIndicator: React.FC<AddIndicatorProps> = ({ isModalVisible, handleOk, handleCancel, form, editingIndex }) => {
  return (
    <Modal
      title={editingIndex !== null ? "Edit Indicator" : "Add Indicator"}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="id" label="Indicator" rules={[{ required: true }]}>
          <Select>
            <Option value="RSA">RSA</Option>
            <Option value="SRSA">SRSA</Option>
            <Option value="MA">MA</Option>
          </Select>
        </Form.Item>
        <Form.Item name="frequency" label="Frequency" rules={[{ required: true }]}>
          <Select>
            <Option value="5">5</Option>
            <Option value="1h">1h</Option>
            <Option value="1d">1d</Option>
            <Option value="1mo">1mo</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddIndicator;