import React, { useState } from "react";
import { Button, Table, Modal, Form, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const BuyCrypto: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [cardData, setCardData] = useState([
    {
        key: "1",
        cardType: "MasterCard",
        cardNumber: "**** **** **** 1234",
      },
      {
        key: "2",
        cardType: "Visa",
        cardNumber: "**** **** **** 5678",
      },
      {
        key: "3",
        cardType: "Visa Electron",
        cardNumber: "**** **** **** 9012",
      },
    ]
  );
  
  const showModal = (p0: string, record: any) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Card Type',
      dataIndex: 'cardType',
      key: 'cardType',
    },
    {
      title: 'Card Number',
      dataIndex: 'cardNumber',
      key: 'cardNumber',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <>
          <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          <Button type="link" icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)} />
        </>
      ),
    },
  ];

  const handleEdit = (record: any) => {
    console.log('Editing record:', record);
    // Assuming showModal is a function to open a modal for editing, and setSelectedRecord is to set the current record for editing
    setSelectedRecord(record);
    showModal('edit', record);
  };
  
  const handleDelete = (key: string) => {
    console.log('Deleting record with key:', key);
    // Assuming cardData is the state that holds the array of card records
    setCardData(prev => prev.filter(item => item.key !== key));
  };

  return (
    <>
      <p>
        Manage the payment method of your credit and debit card on the buy
        crypto page.{" "}
        <div style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={(event) => showModal('add', null)}
          >
            Add Card
          </Button>
        </div>
      </p>
      <Table dataSource={cardData} columns={columns} />
      <Modal
        title="Add/Edit Card"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item label="Card Type" name="cardType">
            <Input />
          </Form.Item>
          <Form.Item label="Card Number" name="cardNumber">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BuyCrypto;
