import React, { useState } from "react";
import { Button, Modal, Checkbox, Tooltip, ConfigProvider } from "antd";
import ProCard from "@ant-design/pro-card";
import enUS from "antd/lib/locale/en_US";

const Preferences: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const options = [
    "Limit Order",
    "Market Order",
    "Stop-Limit Order",
    "Auto Borrow/Repay for Margin",
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log("Selected options:", checkedList);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onCheckboxChange = (list: any) => {
    setCheckedList(list);
  };

  const onSelectAll = (e: any) => {
    if (e.target.checked) {
      setCheckedList(options);
    } else {
      setCheckedList([]);
    }
  };

  return (
    <ProCard title="Preferences" bordered headerBordered>
      <h2>Order Confirmation Reminders</h2>
      <Tooltip title="If the order reminder function is enabled, it will need to be reconfirmed every time an order is submitted.">
        <p>
          If the order reminder function is enabled, it will need to be
          reconfirmed every time an order is submitted.
          <Button onClick={showModal}>Manage</Button>
        </p>
      </Tooltip>
      <ConfigProvider locale={enUS}>
        <Modal
          title="Manage Order Confirmation Reminders"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Checkbox.Group
            options={options}
            value={checkedList}
            onChange={onCheckboxChange}
            style={{ display: "flex", flexDirection: "column" }}
          />
          <Checkbox
            onChange={onSelectAll}
            checked={checkedList.length === options.length}
            style={{ marginTop: "10px" }}
          >
            Select All
          </Checkbox>
        </Modal>
      </ConfigProvider>
    </ProCard>
  );
};

export default Preferences;
