import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import ProCard from "@ant-design/pro-card";

const TwoFactorAuthentication: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <ProCard
        title="Two-Factor Authentication (2FA) Settings"
        extra={
          <Button type="primary" onClick={showModal}>
            Edit 2FA Settings
          </Button>
        }
        bordered
        headerBordered
      >
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>Email:</td>
              <td>[User's Email Here]</td>
            </tr>
            <tr>
              <td>Phone Number:</td>
              <td>[User's Phone Number Here]</td>
            </tr>
            <tr>
              <td>Login Password:</td>
              <td>[Encrypted Password Here]</td>
            </tr>
          </tbody>
        </table>
      </ProCard>

      <Modal
        title="Two-Factor Authentication (2FA) Settings"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Form layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Login Password"
            name="loginPassword"
            rules={[
              { required: true, message: "Please input your login password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TwoFactorAuthentication;
