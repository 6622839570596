import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Layout, Menu, Row } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import "./Header.css";
import { MenuOutlined } from "@ant-design/icons";
import { ReactComponent as LogoWhiteBackground } from "../../../assets/logo_light.svg";
import { ReactComponent as LogoDarkBackground } from "../../../assets/logo_dark.svg";

const { Header } = Layout;

const AppHeader: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { logout, isAuthenticated } = useAuth();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Header
      className={`header page-wrapper ${isScrolled ? "scrolled" : ""}`}
      id="header"
    >
      <Row className="page">
        <Col sm={24} md={6}>
          <a className={`logo ${isScrolled ? "scrolled" : ""}`} href="/">
            <div>
              {isScrolled ? (
                <LogoDarkBackground
                  style={{
                    width: "26px",
                    height: "26px",
                    marginRight: "7px",
                    marginBottom: "-7px",
                  }}
                />
              ) : (
                <LogoWhiteBackground
                  style={{
                    width: "26px",
                    height: "26px",
                    marginRight: "7px",
                    marginBottom: "-7px",
                  }}
                />
              )}
            </div>
            {/* <img
              alt="logo"
              src="https://gw.alipayobjects.com/zos/rmsportal/SVDdpZEbAlWBFuRGIIIL.svg"
            /> */}
            <span>EXCELEXI</span>
          </a>
        </Col>
        <Col sm={0} md={18}>
          <Menu
            className="menu"
            mode="horizontal"
            defaultSelectedKeys={["home"]}
          >
            <Menu.Item
              key="whitePaper"
              className={`menuItem ${isScrolled ? "scrolled" : ""}`}
              onClick={() =>
                window.open("https://excelexi.gitbook.io/white-paper", "_blank")
              }
            >
              Whitepaper
            </Menu.Item>
            <Menu.Item
              key="contact"
              className={`menuItem ${isScrolled ? "scrolled" : ""}`}
            >
              Contact
            </Menu.Item>
            {isAuthenticated ? (
              <>
                <Menu.Item
                  key="3"
                  onClick={logout}
                  className={`menuItem ${isScrolled ? "scrolled" : ""}`}
                >
                  Logout
                </Menu.Item>
                <Menu.Item key="6">
                  <Button type="default">
                    <Link to="/dashboard">Launch App</Link>
                  </Button>
                </Menu.Item>
              </>
            ) : (
              <Menu.Item
                key="7"
                className={`menuItem ${isScrolled ? "scrolled" : ""}`}
              >
                <Link to="/login">Login</Link>
              </Menu.Item>
            )}
          </Menu>
        </Col>
      </Row>
    </Header>
  );
};

export default AppHeader;
