import { Avatar, Col, Row } from "antd";
import React from "react";
import ProCard from "@ant-design/pro-card";

const UserSummary: React.FC = () => (
  <Row justify="space-around" align="middle">
    <Col span={8}>
      <Avatar size="large" src="https://example.com/avatar.jpg" />
    </Col>
    <Col span={8}>
      <p>
        <strong>First Name:</strong> John
      </p>
      <p>
        <strong>Last Name:</strong> Doe
      </p>
    </Col>
    <Col span={8}>
      <p>
        <strong>Status:</strong> Active
      </p>
      <p>
        <strong>Account Type:</strong> Premium
      </p>
    </Col>
  </Row>
);

export default UserSummary;
