import React from 'react';
import { Button, Row, Col } from 'antd';
import ProCard from '@ant-design/pro-card';

const AssetsEstimatedBalance: React.FC = () => (
    <ProCard>
        <Row justify="space-between" align="middle">
            <Col>
                <h2>Estimated Balance: 10,000 USDT</h2>
            </Col>
            <Col>
                <Button type="primary" style={{ marginRight: '10px' }}>Deposit</Button>
                <Button danger style={{ marginRight: '10px' }}>Withdraw</Button>
                <Button>Transfer</Button>
            </Col>
        </Row>
    </ProCard>
);

export default AssetsEstimatedBalance;