import React from 'react';
import TwoFactorAuthentication from './components/TwoFactorAuthentication';

const Dashboard: React.FC = () => (
    <div>
        <h1>Security</h1>
        <TwoFactorAuthentication />
    </div>
);

export default Dashboard;
