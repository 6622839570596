import React from "react";
import UserSummary from "./components/UserSummary";
import EstimatedBalance from "./components/EstimatedBalance";
import RecentTransactions from "./components/RecentTransactions";
import Strategies from "./components/Strategies";
import { Card, Col, Row, Statistic, Tooltip } from "antd";
import { PageContainer } from "@ant-design/pro-layout";
import { InfoCircleOutlined } from "@ant-design/icons";

const Dashboard: React.FC = () => (
  <>
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Card>
          <UserSummary />
        </Card>
      </Col>
    </Row>

    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col span={24}>
          <EstimatedBalance />
      </Col>
    </Row>

    <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
      <Col span={12}>
        <RecentTransactions />
      </Col>
      <Col span={12}>
        <Strategies />
      </Col>
    </Row>
  </>
);

export default Dashboard;
