import React from 'react';
import AssetsEstimatedBalance from './components/AssetsEstimatedBalance';
import RecentTransactions from '../Dashboard/components/RecentTransactions';

const Assets: React.FC = () => (
    <div>
        <AssetsEstimatedBalance />
        <RecentTransactions />
    </div>
);

export default Assets;
