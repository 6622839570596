import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const AppFooter: React.FC = () => (
    <Footer className="dark" id="footer" style={{ textAlign: 'center' }}>
        Excelexi ©{new Date().getFullYear()} Created by Dinem.co.uk
    </Footer>
);

export default AppFooter;
