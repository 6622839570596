import React from 'react';
import { Row, Timeline, Typography } from 'antd';
// import './customStyles.css';

const { Title } = Typography;

const Roadmap: React.FC = () => {
    return (
        <Row style={{ padding: '50px 0' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '40px' }}>Our Roadmap</Title>
                {/* <div className="horizontal-timeline-container"> */}
                    <Timeline mode="alternate" className="horizontal-timeline">
                        <Timeline.Item>Q1, 2024: Launch the Alpha version of the application, incorporating dynamic profit optimization strategies</Timeline.Item>
                        <Timeline.Item>Q2, 2024: Conduct a Beta release of the application for comprehensive testing and user feedback</Timeline.Item>
                        <Timeline.Item>Q3, 2024: Transition the protocol to Web3 by integrating it onto the blockchain through the implementation of smart contracts into the system</Timeline.Item>
                        <Timeline.Item>Q4, 2024: Release the Alpha version of the layer-0 protocol, complete with a user-friendly toolkit</Timeline.Item>
                       
                        <Timeline.Item>Q1, 2025: Roll out the Beta version of the layer-0 protocol, featuring the Marketplace</Timeline.Item>
                        <Timeline.Item>Q2, 2025: Conduct a thorough audit of the entire system</Timeline.Item>
                        <Timeline.Item>Q3, 2025: General release of the protocol to the public</Timeline.Item>
                        <Timeline.Item>Q4, 2025: Launch a strategic marketing campaign to promote the protocol</Timeline.Item>
                       
                        <Timeline.Item>Q1, 2026: Introduce additional tools and functionalities to enhance the building of strategies</Timeline.Item>
                        <Timeline.Item>Q2, 2026: Conduct a Beta release of the application for comprehensive testing and user feedback</Timeline.Item>
                        <Timeline.Item>Q3, 2026: Transition the protocol to Web3 by integrating it onto the blockchain through the implementation of smart contracts into the system</Timeline.Item>
                        <Timeline.Item>Q4, 2026: Release the Alpha version of the layer-0 protocol, complete with a user-friendly toolkit</Timeline.Item>
                       
                    </Timeline>
                {/* </div> */}
            </div>
        </Row>
    );
};

export default Roadmap;