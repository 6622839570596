import React, { useContext } from 'react';
import { Button, Layout, Menu, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ProLayout, { PageContainer } from '@ant-design/pro-layout';
import { ReactComponent as LogoWhiteBackground } from "../../assets/logo_dark.svg";

import {
    DashboardOutlined,
    FileTextOutlined,
    HistoryOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    UserOutlined,
} from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;

interface CustomLayoutProps {
    children: React.ReactNode;
}

// Define a custom logo component if needed
// const CustomLogo = () => (
//   <div style={{ display: 'flex', alignItems: 'center', height: '32px', margin: '16px' }}>
//     <img src={logoImage} alt="Logo" style={{ height: '100%' }} />
//     {/* Or any other custom logo representation */}
//   </div>
// );

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    return (
        <ProLayout
            title="Excelexi"
            navTheme="light"
            layout="mix"
            logo={<LogoWhiteBackground style={{ height: '26px', width: '26px' }} />}
            headerRender={(props, defaultDom) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  {defaultDom}
                  <Space>
                    {/* <Button type="text"  icon={<QuestionCircleOutlined />} /> */}
                    <Button type="text" onClick={()=>navigate("/settings")} icon={<SettingOutlined />} />
                    <Button type="text" onClick={()=>navigate("/account/identification")} icon={<UserOutlined />} />
                  </Space>
                </div>
              )}
            menuItemRender={(item, dom) => <Link to={item.path || ''}>{dom}</Link>}
            menuDataRender={() => [
                { path: '/dashboard', name: 'Dashboard' },
                { path: '/assets', name: 'Assets' },
                { path: '/strategies', name: 'Strategies' },
                { path: '/settings', name: 'Settings' },
                {
                    path: '/account',
                    name: 'Account',
                    children: [
                        { path: '/account/identification', name: 'Identification' },
                        { path: '/account/security', name: 'Security' },
                        { path: '/account/payment', name: 'Payment' },
                        // { path: '/account/api-management', name: 'API Management' },
                        { path: '/account/account-statement', name: 'Account Statement' },
                    ],
                },
            ]}
        >
            <PageContainer>{children}</PageContainer>
        </ProLayout>
    );
};

export default CustomLayout;
