import React from 'react';
import AccountLimits from './components/AccountLimits';
import PersonalInformation from './components/PersonalInformation';
import CustomerSupport from './components/CustomerSupport';

const Dashboard: React.FC = () => (
    <div>
        <AccountLimits />
        <PersonalInformation />
        <CustomerSupport />
    </div>
);

export default Dashboard;
