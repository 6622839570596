import { Card, Tooltip } from 'antd';
import React from 'react';

const EstimatedBalance: React.FC = () => (
    <Card title="Estimated Balance" >
        <Tooltip title="This number represents the total estimated balance of your assets in BTC.">
            <p><strong>Total Assets:</strong> 1.5 BTC</p>
        </Tooltip>
    </Card>
);

export default EstimatedBalance;