import React from 'react';
import { Table, Tag, Button } from 'antd';

const StrategyList: React.FC = () => {
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Deposit',
            dataIndex: 'deposit',
            key: 'deposit',
        },
        {
            title: 'Invested',
            dataIndex: 'invested',
            key: 'invested',
        },
        {
            title: 'Assets',
            dataIndex: 'assets',
            key: 'assets',
        },
        {
            title: 'Estimated Value',
            dataIndex: 'estimatedValue',
            key: 'estimatedValue',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined) => (
                <Tag color={status === 'Available' ? 'green' : 'volcano'}>{status}</Tag>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: any) => (
                <>
                    <Button type="primary" style={{ marginRight: 8 }}>Deposit</Button>
                    <Button danger>Withdraw</Button>
                </>
            ),
        },
    ];

    const data = [
        // Sample data
        { no: 1, name: 'Strategy A', created: '2023-01-01', duration: '6 months', deposit: '$10,000', invested: '$8,000', assets: 'BTC, ETH', estimatedValue: '$15,000', status: 'Available' },
        { no: 2, name: 'Strategy B', created: '2023-02-01', duration: '1 year', deposit: '$20,000', invested: '$18,000', assets: 'ETH, LTC', estimatedValue: '$25,000', status: 'Frozen' },
        // Add more data as needed
    ];

    return (
        <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
    );
};

export default StrategyList;