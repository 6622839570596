import React from 'react';
import { Table, Tag } from 'antd';
import ProCard from '@ant-design/pro-card';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

const RecentTransactions: React.FC = () => {
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text: string) => (
                text === 'Buy' ? <Tag color="green"><UpOutlined /> Buy</Tag> : <Tag color="red"><DownOutlined /> Sell</Tag>
            ),
        },
    ];

    const data = [
        { no: 1, date: '2023-04-01', amount: '0.5 BTC', type: 'Buy' },
        { no: 2, date: '2023-04-02', amount: '0.2 BTC', type: 'Sell' },
        { no: 3, date: '2023-04-03', amount: '1.0 BTC', type: 'Buy' },
        { no: 4, date: '2023-04-04', amount: '0.3 BTC', type: 'Sell' },
        { no: 5, date: '2023-04-05', amount: '0.4 BTC', type: 'Buy' },
    ];

    return (
        <ProCard title="Recent Transactions" bordered headerBordered>
            <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
        </ProCard>
    );
};

export default RecentTransactions;