import React from 'react';
import MyProfile from './components/MyProfile';
import Preferences from './components/Preferences';

const Settings: React.FC = () => (
    <div>
        <MyProfile  />
        <Preferences />
    </div>
);

export default Settings;
