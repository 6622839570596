import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Steps,
  Table,
  Tag,
  theme,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import AddIndicator from "./AddIndicator";

const { Step } = Steps;
const { Option } = Select;

interface IIndicator {
  id: number;
  name: string;
  frequency: string;
}

interface IStrategy {
  id: string;
  name: string;
  description: string;
  asset: string;
  funds: string;
  created: Date;
  duration: number;
  deposit: number;
  invested: number;
  estimatedValue: number;
  status: string;
  tags: string[];

  indicators: IIndicator[];
}

const AddStrategy: React.FC = () => {
  const { token } = theme.useToken();
  const [currentStep, setCurrentStep] = useState(0);
  const [indicators, setIndicators] = useState<IIndicator[]>([
    { id: 0, name: "RSA", frequency: "5" },
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null); // Track the editing indicator's index
  const [formData, setFormData] = useState<IStrategy>({
    id: "",
    name: "",
    description: "",
    asset: "",
    funds: "",
    created: new Date(),
    duration: 0,
    deposit: 0,
    invested: 0,
    estimatedValue: 0,
    status: "",
    tags: [],
    indicators: [],
  });
  const [form] = Form.useForm();

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    padding: 20,
    marginTop: 16,
  };

  const nextStep = () => {
    form
      .validateFields()
      .then(() => {
        // Check if the current step is the last step
        if (currentStep < steps.length - 1) {
          // Not the last step, proceed to the next step
          setCurrentStep(currentStep + 1);
        } else {
          // This is the last step, perhaps finalize the form or indicate completion
          console.log("Form completion or finalization logic here");
        }
      })
      .catch((errorInfo) => {
        // Validation failed, stay on the current step
        console.log("Validation Failed:", errorInfo);
      });
  };

  const prevStep = () => {
    setCurrentStep((prevCurrentStep) => Math.max(0, prevCurrentStep - 1));
  };
  const updateFormData = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const addIndicator = () => {
    if (indicators.length < 10) {
      const newIndicator: IIndicator = {
        id: indicators.length + 1,
        name: `NewIndicator${indicators.length + 1}`,
        frequency: "5",
      };
      setIndicators([...indicators, newIndicator]);
    } else {
      alert("Maximum of 10 indicators reached");
    }
  };

  const updateIndicator = <T extends keyof IIndicator>(
    index: number,
    field: T,
    value: IIndicator[T]
  ) => {
    const newIndicators: IIndicator[] = [...indicators];
    newIndicators[index][field] = value;
    setIndicators(newIndicators);
  };

  const showModalToAdd = () => {
    setEditingIndex(null); // Reset editing index
    form.resetFields(); // Reset form for adding
    setIsModalVisible(true);
  };

  const showModalToEdit = (index: number) => {
    setEditingIndex(index); // Set editing index
    form.setFieldsValue(indicators[index]); // Prefill form with indicator's data
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Check for duplicate indicator
        const isDuplicate = indicators.some((indicator, index) => {
          return (
            values.id === indicator.id &&
            values.frequency === indicator.frequency &&
            index !== editingIndex
          );
        });

        if (isDuplicate) {
          // Alert or show a message to the user about the duplicate
          notification.error({
            message: "Duplicate Indicator",
            description: "Duplicate indicator not allowed.",
            duration: 4,
          });
          return;
        }

        if (editingIndex !== null) {
          // Edit mode
          const updatedIndicators = [...indicators];
          updatedIndicators[editingIndex] = values;
          setIndicators(updatedIndicators);
        } else {
          // Add mode
          setIndicators([...indicators, values]);
        }
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deleteIndicator = (index: number) => {
    const newIndicators = [...indicators];
    newIndicators.splice(index, 1);
    setIndicators(newIndicators);
  };

  const columns = [
    {
      title: "Indicator",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any, index: number) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => showModalToEdit(index)}
          />
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => deleteIndicator(index)}
          />
        </Space>
      ),
    },
  ];

  const reviewColumns = [
    {
      title: "Indicator",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: number) =>
        `Indicator ${index + 1}`,
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => setCurrentStep(2)}
        />
      ),
    },
  ];

  const steps = [
    {
      title: "Info",
      content: (
        <>
          <Form.Item
            label="Name"
            rules={[
              { required: true, message: "Please input the strategy name!" },
            ]}
          >
            <Input
              value={formData.name}
              onChange={(e) => updateFormData("name", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <Input.TextArea
              value={formData.description}
              onChange={(e) => updateFormData("description", e.target.value)}
              rows={4} // You can adjust the number of rows as needed
            />
          </Form.Item>
          <Form.Item
            label="Tags"
            rules={[
              { required: true, message: "Please select at least one tag!" },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Tags"
              onChange={(value) => updateFormData("tags", value)}
            >
              {/* Options can be dynamically generated */}
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: "Funds",
      content: (
        <>
          <p>Your wallet currently has $100,00</p>
          <Form.Item
            label="Funds from Wallet"
            rules={[{ required: true, message: "Please input the funds!" }]}
          >
            <Input
              prefix="$"
              value={formData.funds}
              onChange={(e) => updateFormData("funds", e.target.value)}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Details",
      content: (
        <>
          <Button icon={<PlusOutlined />}  onClick={showModalToAdd}>Add Indicator</Button>
          <ConfigProvider locale={enUS}>
            <Table dataSource={indicators} columns={columns} rowKey="id" />
            <AddIndicator
              isModalVisible={isModalVisible}
              handleOk={handleOk}
              handleCancel={handleCancel}
              form={form}
              editingIndex={editingIndex}
            />
          </ConfigProvider>
        </>
      ),
    },
    {
      title: "Review",
      content: (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <p>
                Name{" "}
                <EditOutlined
                  onClick={() => setCurrentStep(0)}
                  style={{ cursor: "pointer" }}
                />
                :
              </p>
            </Col>
            <Col span={8}>
              <p>{formData.name}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <p>
                Description{" "}
                <EditOutlined
                  onClick={() => setCurrentStep(0)}
                  style={{ cursor: "pointer" }}
                />{" "}
                :
              </p>
            </Col>
            <Col span={8}>
              <p>{formData.description}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <p>
                Tags{" "}
                <EditOutlined
                  onClick={() => setCurrentStep(0)}
                  style={{ cursor: "pointer" }}
                />
                :
              </p>
            </Col>
            <Col span={8}>
              <p>
                {formData.tags.map((tag) => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <p>
                Funds{" "}
                <EditOutlined
                  onClick={() => setCurrentStep(1)}
                  style={{ cursor: "pointer" }}
                />
                :
              </p>
            </Col>
            <Col span={8}>
              <p>{formData.funds}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16}>
              <Table
                dataSource={indicators}
                columns={reviewColumns}
                rowKey="id"
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <div style={contentStyle}>
        <Form form={form} layout="vertical">
          <div className="steps-content">{steps[currentStep].content}</div>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <>
                {currentStep > 0 && (
                  <Button
                    icon={<LeftOutlined />}
                    style={{ margin: "0 8px" }}
                    onClick={prevStep}
                  >
                    Previous
                  </Button>
                )}
                <Button type="primary" onClick={nextStep}>
                  Next <RightOutlined />
                </Button>
              </>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => console.log("Process Complete")}
              >
                Start <PlayCircleOutlined />
              </Button>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddStrategy;
