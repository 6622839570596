import React from 'react';
import { Table } from 'antd';
import ProCard from '@ant-design/pro-card';

const Strategies: React.FC = () => {
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Deposit',
            dataIndex: 'deposit',
            key: 'deposit',
        },
        {
            title: 'Invested',
            dataIndex: 'invested',
            key: 'invested',
        },
        {
            title: 'Assets',
            dataIndex: 'assets',
            key: 'assets',
        },
        {
            title: 'Estimated Balance',
            dataIndex: 'estimatedBalance',
            key: 'estimatedBalance',
        },
    ];

    const data = [
        // Sample data
        { no: 1, name: 'Strategy A', deposit: '$10,000', invested: '$8,000', assets: 'BTC, ETH', estimatedBalance: '$15,000' },
        { no: 2, name: 'Strategy B', deposit: '$20,000', invested: '$18,000', assets: 'ETH, LTC', estimatedBalance: '$25,000' },
        // Add more data as needed
    ];

    return (
        <ProCard title="Strategies" bordered headerBordered>
            <Table columns={columns} dataSource={data} pagination={{ pageSize: 10 }} />
        </ProCard>
    );
};

export default Strategies;