import React, { useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import ProCard from "@ant-design/pro-card";

const PersonalInformation: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    legalName: "[Legal Name Here]",
    dateOfBirth: "[Date of Birth Here]",
    address: "[Address Here]",
    identificationDocuments: "[Identification Documents Here]",
    emailAddress: "[Email Address Here]",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFormChange = (changedValues: any, allValues: any) => {
    setFormData(allValues);
  };

  return (
    <>
      <ProCard
        title="Personal Information"
        extra={
          <Button type="primary" onClick={showModal}>
            Edit
          </Button>
        }
        bordered
        headerBordered
      >
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>Legal Name:</td>
              <td>{formData.legalName}</td>
            </tr>
            <tr>
              <td>Date of Birth:</td>
              <td>{formData.dateOfBirth}</td>
            </tr>
            <tr>
              <td>Address:</td>
              <td>{formData.address}</td>
            </tr>
            <tr>
              <td>Identification Documents:</td>
              <td>{formData.identificationDocuments}</td>
            </tr>
            <tr>
              <td>Email Address:</td>
              <td>{formData.emailAddress}</td>
            </tr>
          </tbody>
        </table>
      </ProCard>
      <Modal
        title="Edit Personal Information"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="OK"
        cancelText="Cancel"
      >
        <Form
          layout="vertical"
          onValuesChange={onFormChange}
          initialValues={formData}
        >
          <Form.Item label="Legal Name" name="legalName">
            <Input />
          </Form.Item>
          <Form.Item label="Date of Birth" name="dateOfBirth">
            <Input />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>
          <Form.Item
            label="Identification Documents"
            name="identificationDocuments"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Email Address" name="emailAddress">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PersonalInformation;
