import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Card, Form, Input, Button, Layout, Alert, message, Tabs, Spin, Checkbox } from "antd";
import { useAuth } from "../../context/AuthContext";
import { ReactComponent as LogoDarkBackground } from "../../assets/logo_dark.svg";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;

const Login: React.FC = () => {
  const { login, isAuthenticated } = useAuth();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await login(username, password);
      message.success("Login successful");
      setLoading(false);
    } catch (err) {
      message.error("Username or password is incorrect");
      setLoading(false);
    }
  };

  // Function to handle the "Remember Me" checkbox state
  const setRememberMe = (isChecked: boolean) => {
    // Here you can implement what happens when the checkbox is checked/unchecked
    // For example, saving the state to local storage or component state
    console.log("Remember Me checked:", isChecked);
  };

  // Function to handle the "Forgot Password" button click
  const handleForgotPassword = () => {
    // Here you can implement the logic to navigate to the forgot password page or open a modal
    console.log("Forgot Password clicked");
    // Example: navigate to forgot password page
    // navigate('/forgot-password');
  };

  return (
    <>
      <Layout className="layout" style={{ minHeight: "100vh" }}>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "50px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "50px" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
              <LogoDarkBackground
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "7px",
                }} />
              <h1 style={{ marginBottom: "0px" }}>Excelexi</h1>
            </div>
            <p style={{ marginBottom: "40px" }}>Automated Trading Platform</p>


            <Card style={{ width: 500 }}>
              <div style={{ textAlign: "center", marginBottom: "25px" }}>
                <h1 style={{ fontSize: "22px", color: "blue" }}>Welcome back!</h1>
                <p style={{ fontSize: "14px", color: "grey" }}>Sign in to continue to Excelexi </p>
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <Form onFinish={handleSubmit} layout="vertical">
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[{ required: true, message: "Please input your username!" }]}
                >
                  <Input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                >
                  <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item style={{ marginBottom: 25 }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox onChange={(e) => setRememberMe(e.target.checked)}>Remember me</Checkbox>
                  </Form.Item>
                  <Button type="link" onClick={handleForgotPassword} style={{ float: "right" }}>
                    Forgot password?
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block disabled={loading} icon={loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <p>Don't have an account? <a href="/signup">Signup</a></p>
            </Card>
          </div>
        </Content>
      </Layout >
    </>
  );
};

export default Login;
