import React, { useState } from 'react';
import { DatePicker, Select, Button, Table, Pagination, ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
const { RangePicker } = DatePicker;
const { Option } = Select;

const AccountStatement: React.FC = () => {
  const [transactions, setTransactions] = useState([]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  return (
    <div>
    <h1>Account Statement</h1>
    <div>
    <ConfigProvider locale={enUS}>
      <RangePicker />
    </ConfigProvider>
      <Select placeholder="Accounts" style={{ width: 120, margin: '0 8px' }}>
        <Option value="account1">Account 1</Option>
        <Option value="account2">Account 2</Option>
      </Select>
      <Select placeholder="Wallets" style={{ width: 120, margin: '0 8px' }}>
        <Option value="wallet1">Wallet 1</Option>
        <Option value="wallet2">Wallet 2</Option>
      </Select>
      <Button type="primary" style={{ margin: '0 8px' }}>Search</Button>
      <Button>Reset</Button>
    </div>
    <div>
      <p>Date: {/* Date here */}</p>
      <p>UserId: {/* UserId here */}</p>
      <p>Account Type: {/* Account Type here */}</p>
      <p>Wallet: {/* Wallet here */}</p>
      <p>Rate: {/* Rate here */}</p>
    </div>
    <ConfigProvider locale={enUS}>
      <Table dataSource={transactions} columns={columns} pagination={false} />
    </ConfigProvider>
    <Pagination style={{ marginTop: '16px' }} defaultCurrent={1} total={50} />
  </div>
  );
};

export default AccountStatement;
